.App {
  text-align: center;
  font-family: "omyu_pretty";
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Btn {
  background-color: pink;
  border: none;
  color: #3b3b3b;
  padding: 5px 10px;
  font-size: 1.2rem;
  cursor: pointer;
  margin: 10px;
  border-radius: 5px;
  font-family: "omyu_pretty";
}

.Input {
  padding: 5px 10px;
  font-size: 1.2rem;
  margin: 10px;
  border-radius: 5px;
  border: 1px solid #3b3b3b;
}

.title {
  font-size: 4rem;
  font-family: "bold-blaster-italic";
  margin: 10px;
}

@font-face {
  font-family: "omyu_pretty";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2304-01@1.0/omyu_pretty.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bold-blaster";
  src: url("./assets/font/BoldblasterRegular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bold-blaster-italic";
  src: url("./assets/font/BoldblasterItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
